export const styles = {
    innerContainer: {
        //   mx: 1,
        height: 'calc(85% - 72px)',
        mb: 3,
        display: 'flex',
        flexDirection: 'column',
    },
    buttonList: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '8px',
        columnGap: '5px',
        paddingBottom: '8px',
    },
    logsheetStationsList: {
        flex: 1,
        display: 'flex',
        paddingLeft: 0,
    },
    cardContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 2,
        mt: '10px',
        mb: 12,
        paddingBottom: '70px',
    },
    paper: {
        borderRadius: 12,
        padding: 20,
        width: '250px',
        height: '210px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    paperSignoff: {
        borderRadius: 12,
        padding: 20,
        width: '250px',
        height: '240px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    image: { objectFit: 'cover', width: '100%', height: '100%' },
    cardImageContainer: { width: '250px', height: '175px' },
    cardImageSmallContainer: {
        width: '100%',
        height: '113px',
        paddingTop: '16px',
        paddingBottom: '5px',
    },
    cardTextContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',

        fontFamily: 'Montserrat',
        fontWeight: 400,
        '& *': {
            fontFamily: 'inherit', // Enforce the font for all children elements
        },
    },

    cardLogsheetStationInfo: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        gap: '20px',
        marginTop: 0,
        minHeight: '200px',
        minWidth: '200px',
        maxWidth: '300px',

        fontFamily: 'Montserrat',
        '& *': {
            fontFamily: 'inherit', // Enforce the font for all children elements
        },
    },
    cardTextBig: {
        fontWeight: 700,
        color: '#363636',
        wordWrap: 'break-word',
        fontSize: '16px',
        textTransform: 'uppercase',
        paddingBottom: '12px',
        letterSpacing: '-0.5px',
        lineHeight: '1.2',
    },
    cardTextBigZone: {
        fontWeight: 700,
        color: '#363636',
        wordWrap: 'break-word',

        fontSize: '16px',
        textTransform: 'uppercase',
        paddingBottom: '0',
        letterSpacing: '-0.5px',
        lineHeight: '1.2',
    },

    cardTextSmallerBlock: {
        color: '#363636',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        wordWrap: 'break-word',
    },
    cardTextSmall: {
        fontSize: '12px',
    },
    cardTextSmallBold: {
        fontSize: '12px',
        fontWeight: 700,
    },
    cardTextSmallerBlockLogsheet: {
        color: '#363636',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        gap: 10,
    },
    station: {
        margin: '2%',
        padding: '3%',
        paddingLeft: '4%',
        width: '300px',
        backgroundColor: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    cardTextStationBig: {
        fontWeight: 700,
        color: '#363636',

        fontSize: '16px',
        textTransform: 'uppercase',
        paddingBottom: '6px',
        paddingTop: '6px',

        letterSpacing: '-0.5px',
        lineHeight: '1.2',
    },
    cardTextStationSmallerBlock: {
        fontSize: '14px',
    },
    cardTextStationRedBlock: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: '5px',
    },
    cardTextStationSmallRed: {
        color: '#363636',
        fontSize: '12px',
        fontStyle: 'italic',
        paddingLeft: '10px',
    },
    cardTextLogsheetRedBlock: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: '5px',
        marginTop: 0,
    },
    cardTextLogsheetRedBlockWrap: {
        minHeight: '60px',
    },
}
