import React, { useState, useEffect } from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
} from '@mui/material'
import {
    getAllMasterLogsheets,
    updatePortLogsheetStationOrdering,
} from '../../Logsheets/actions'
import { useToastContext } from 'src/context/ToastContext'

const PortLogsheetOrderingModal = ({ setDisplayModal, users }) => {
    const [sourceUser, setSourceUser] = useState('')
    const [targetUser, setTargetUser] = useState('')
    const [logsheet, setLogsheet] = useState('')
    const [logsheets, setLogsheets] = useState([])
    const toastContext = useToastContext()

    useEffect(() => {
        const fetchData = async () => {
            const fetchedLogsheets = await getAllMasterLogsheets()
            setLogsheets(fetchedLogsheets)
        }
        fetchData()
    }, [])

    const handleSubmit = async () => {
        if (sourceUser && targetUser && logsheet) {
            try {
                await updatePortLogsheetStationOrdering({
                    sourceUserId: sourceUser,
                    targetUserId: targetUser,
                    logsheetId: logsheet,
                })
                toastContext.sendToast(
                    'Logsheet ordering updated successfully',
                    'success'
                )
                setDisplayModal(false)
            } catch (error) {
                toastContext.sendToast(
                    error.message || 'Error porting logsheet ordering',
                    'error'
                )
            }
        } else {
            toastContext.sendToast('Please select all required fields', 'error')
        }
    }

    return (
        <Dialog
            open={true}
            onClose={() => setDisplayModal(false)}
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle>Port Logsheet Ordering</DialogTitle>
            <DialogContent>
                <Box sx={{ mt: 2 }}>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel id="source-user-label">
                            Source User
                        </InputLabel>
                        <Select
                            labelId="source-user-label"
                            value={sourceUser}
                            onChange={(e) => setSourceUser(e.target.value)}
                            label="Source User"
                        >
                            {users.map((user) => (
                                <MenuItem
                                    key={user.Username}
                                    value={user.Username}
                                >
                                    {
                                        user.Attributes.find(
                                            (attribute) =>
                                                attribute.Name === 'email'
                                        ).Value
                                    }
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel id="target-user-label">
                            Target User
                        </InputLabel>
                        <Select
                            labelId="target-user-label"
                            value={targetUser}
                            onChange={(e) => setTargetUser(e.target.value)}
                            label="Target User"
                        >
                            {users.map((user) => (
                                <MenuItem
                                    key={user.Username}
                                    value={user.Username}
                                >
                                    {
                                        user.Attributes.find(
                                            (attribute) =>
                                                attribute.Name === 'email'
                                        ).Value
                                    }
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel id="logsheet-label">Logsheet</InputLabel>
                        <Select
                            labelId="logsheet-label"
                            value={logsheet}
                            onChange={(e) => setLogsheet(e.target.value)}
                            label="Logsheet"
                        >
                            {logsheets.map((logsheet) => (
                                <MenuItem key={logsheet.id} value={logsheet.id}>
                                    {logsheet.masterLogsheetTitle}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setDisplayModal(false)}>Cancel</Button>
                <Button onClick={handleSubmit} color="primary">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default PortLogsheetOrderingModal
