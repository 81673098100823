import axios from 'axios'
import {
    convertDataToMeasurementsFormat,
    generateExcelReport,
} from '../../utils/excel'

//This file contains functions that use axios to send and get information from the backend
export const getAllLogsheetDataForOfflineUse = async () => {
    return await axios
        .get('/api/logsheets-for-offline-use')
        .then(({ data }) => data)
        .catch((err) => console.error(err))
}

export const getAllMasterLogsheets = async () => {
    return await axios.get('/api/logsheets').then(({ data }) => data)
}

export const updateUserFieldOfWork = async (masterLogsheetIds) => {
    return await axios
        .post('/api/user/field-of-work', { masterLogsheetIds })
        .then(({ data }) => data)
}

export const getUserFieldOfWork = async () => {
    try {
        const response = await axios.get('/api/user/field-of-work')
        return response.data
    } catch (error) {
        console.error('Error fetching user field of work:', error)
        throw error
    }
}

export const createMasterLogsheet = async (
    masterLogsheetTitle,
    stationIds,
    zoneIds,
    organizationId
) => {
    return await axios
        .post('/api/logsheets', {
            masterLogsheetTitle,
            stationIds,
            zoneIds,
            organizationId: '1',
        })
        .then(({ data }) => data)
        .catch((err) => console.error(err))
}

export const getIndividualMasterLogsheet = async (masterLogsheetId) => {
    return await axios
        .get(`/api/logsheets/${masterLogsheetId}`)
        .then(({ data }) => data)
}

export const updateMasterLogsheet = async (
    masterLogsheetId,
    masterLogsheetTitle,
    stationIds,
    zoneIds
) => {
    return await axios
        .patch(`/api/logsheets/${masterLogsheetId}`, {
            masterLogsheetTitle,
            organizationId: '1',
            stationIds,
            zoneIds,
        })
        .then(({ data }) => data)
}

export const deleteMasterLogsheet = async (masterLogsheetId) => {
    return await axios
        .delete(`/api/logsheets/${masterLogsheetId}`)
        .then(({ data }) => data)
}

export const getAllLogsheets = async (masterLogsheetId) => {
    return await axios
        .get(`/api/logsheets/${masterLogsheetId}`)
        .then(({ data }) => data)
        .catch((err) => console.error(err))
}
export const getMasterLogsheetMeasurements = async (
    masterLogsheetId,
    startDate,
    endDate
) => {
    const params = { startDate, endDate }

    return await axios
        .get(`/api/logsheets/${masterLogsheetId}/measurements`, { params })
        .then(({ data }) => data)
        .catch((err) => console.error(err))
}

export const getIndividualLogsheet = async (masterLogsheetId, logsheetId) => {
    return await axios.get(
        `/api/logsheets/${masterLogsheetId}/logsheet/${logsheetId}`
    )
}

export const createLogsheet = async (
    logsheetTitle,
    stationIds,
    masterLogsheetId,
    organizationId
) => {
    return await axios
        .post(`/api/logsheets/${masterLogsheetId}/logsheet`, {
            logsheetTitle,
            stationIds,
            organizationId: '1',
        })
        .then(({ data }) => data)
}

export const updateLogsheet = async (
    logsheetId,
    masterLogsheetId,
    logsheetTitle,
    stationIds,
    organizationId
) => {
    return await axios
        .patch(`/api/logsheets/${masterLogsheetId}/logsheet/${logsheetId}`, {
            logsheetTitle,
            stationIds,
            organizationId: '1',
        })
        .then(({ data }) => data)
}

export const deleteLogsheet = async (logsheetId, masterLogsheetId) => {
    return await axios
        .delete(`/api/logsheets/${masterLogsheetId}/logsheet/${logsheetId}`)
        .then(({ data }) => data)
}

export const getLogsheetOrdering = async (payload) => {
    return await axios
        .get(`/api/logsheets/orderings`, payload)
        .then(({ data }) => data)
}

export const updateLogsheetOrdering = async (payload) => {
    return await axios
        .post(`/api/logsheets/orderings`, payload)
        .then(({ data }) => data)
        .catch((err) => console.error(err))
}

export const getLogsheetStationOrdering = async (logsheetId, payload) => {
    return await axios
        .get(`/api/logsheets/${logsheetId}/stations/orderings`, payload)
        .then(({ data }) => data)
        .catch((err) => console.error(err))
}

export const getMainLogsheetStationOrdering = async (
    masterLogsheetId,
    payload
) => {
    return await axios
        .get(
            `/api/logsheets/${masterLogsheetId}/stations/orderings-default`,
            payload
        )
        .then(({ data }) => data)
        .catch((err) => console.error(err))
}

export const updatePortLogsheetStationOrdering = async (payload) => {
    try {
        const response = await axios.post(
            `/api/logsheets/port-logsheet-station-ordering`,
            payload
        )
        return response.data
    } catch (error) {
        if (error.response && error.response.data) {
            throw new Error(error.response.data.message || 'An error occurred')
        }
        throw error
    }
}

export const updateLogsheetStationOrdering = async (logsheetId, payload) => {
    return await axios
        .post(`/api/logsheets/${logsheetId}/stations/orderings`, payload)
        .then(({ data }) => data)
        .catch((err) => console.error(err))
}

export const minifiedGetAllZonesWithStations = async () => {
    return await axios
        .get('/api/minified-zones-with-stations')
        .then(({ data }) => data)
        .catch((err) => console.error(err))
}

export const downloadMasterLogsheetReport = async (masterLogsheetId) => {
    return await axios
        .get(`/api/logsheets/export/${masterLogsheetId}`)
        .then(({ data }) => convertDataToMeasurementsFormat(data))
        .then((measurements) => generateExcelReport(measurements))
        .catch((err) => console.error(err))
}

export const createLogsheetSignOff = async (
    masterLogsheetId,
    id,
    arrayOfStations
) => {
    // the id parameter is referring to the Individual Logsheet ID.
    return await axios
        .post(
            `/api/logsheets/${masterLogsheetId}/logsheet/${id}/signoff`,
            arrayOfStations
        )
        .then(({ data }) => data)
}

export const getMasterLogsheetSignOffs = async (masterLogsheetId) => {
    return await axios
        .get(`/api/logsheets/${masterLogsheetId}/signoffs`)
        .then(({ data }) => data)
        .catch((err) => console.error(err))
}

export const getMainLogsheet = async (masterLogsheetId) => {
    return await axios
        .get(`/api/logsheets/${masterLogsheetId}/main`)
        .then(({ data }) => data)
}

export const updateMainLogsheetStationOrdering = async (
    masterLogsheetId,
    payload
) => {
    return await axios
        .post(
            `/api/logsheets/${masterLogsheetId}//stations/orderings-default`,
            payload
        )
        .then(({ data }) => data)
        .catch((err) => console.error(err))
}
