import axios from 'axios'

export const getAllMasterLogbooks = async () => {
    return await axios.get(`/api/masterLogbooks`).then(({ data }) => data)
}

export const getMasterLogbook = async (logbookId, searchTerm = '') => {
    return await axios
        .get(`/api/masterLogbooks/${logbookId}`, {
            params: { searchTerm },
        })
        .then(({ data }) => data)
}

export const getLogbook = async (logbookId) => {
    return await axios
        .get(`/api/logbooks/${logbookId}`)
        .then(({ data }) => data)
}

export const getLogbookPromptsAndCalculations = async (logbookId) => {
    return await axios
        .get(`/api/logbooks/${logbookId}/prompts-and-calculations`)
        .then(({ data }) => data)
}

export const createLogbook = async (masterLogbookId) => {
    return await axios
        .post(`/api/logbooks`, { masterLogbookId })
        .then(({ data }) => data)
}

export const createLogbookComment = async (logbookId, comment) => {
    return await axios
        .post(`/api/logbooks/${logbookId}/comments`, { comment })
        .then(({ data }) => data)
}

// export const createCompiledLogbookPrompt = async (prompt) => {
//     return await axios
//         .post(`/api/logbooks/prompts`, { prompt })
//         .then(({ data }) => data)
// }

export const createLogbookPrompt = async (
    masterLogbookId,
    logbookId,
    prompt
) => {
    return await axios
        .post(
            `/api/masterLogbooks/${masterLogbookId}/logbooks/${logbookId}/prompts`,
            { prompt }
        )
        .then(({ data }) => data)
}

export const updateLogbookPrompt = async (logbookId, promptId, body) => {
    return await axios
        .patch(`/api/logbooks/${logbookId}/prompts/${promptId}`, body)
        .then(({ data }) => data)
}

export const updateLogbookCalculation = async (
    logbookId,
    calculationId,
    body
) => {
    return await axios
        .patch(`/api/logbooks/${logbookId}/calculation/${calculationId}`, body)
        .then(({ data }) => data)
}

export const updateLogbookComment = async (logbookId, commentId, body) => {
    return await axios
        .patch(`/api/logbooks/${logbookId}/comments/${commentId}`, body)
        .then(({ data }) => data)
}

export const deleteLogbookPrompt = async (
    logbookId,
    masterLogbookId,
    promptId
) => {
    return await axios
        .delete(
            `/api/masterLogbooks/${masterLogbookId}/logbooks/${logbookId}/prompts/${promptId}`
        )
        .then(({ data }) => data)
}

export const createLogbookSignOff = async (logbookId) => {
    return await axios
        .post(`/api/logbooks/${logbookId}/signoff`)
        .then(({ data }) => data)
}

export const getLogbookMissedMeasurements = async (logbookId) => {
    return await axios
        .get(`/api/logbooks/${logbookId}/missed-measurements`)
        .then(({ data }) => data)
}

export const getUnsignedLogbooks = async () => {
    return await axios
        .get(`/api/user/unsigned-logbooks`)
        .then(({ data }) => data)
}

export const getUserLogbookSignoffs = async () => {
    return await axios
        .get(`/api/user/logbook-signoffs`)
        .then(({ data }) => data)
}

export const getAllLogbookSignoffs = async () => {
    return await axios
        .get(`/api/admin/logbook-signoffs`)
        .then(({ data }) => data)
}
