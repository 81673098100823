import React, { useEffect, useState } from 'react'
import {
    Box,
    Table,
    TableContainer,
    TableRow,
    TableCell,
    TableBody,
    TableHead,
    Paper,
    Button,
    Typography,
} from '@mui/material'
import { getAllUsers } from './actions'
import { styles } from '../../theme/styles'
import CreateOrUpdateOrganization from './CreateOrUpdateOrganization'
import { useLoader } from '../../hooks/useLoader'
import { useShowContentBasedOnRoles } from '../../hooks/useShowContentBasedOnRoles'
import EditUser from './EditUser'
import PortLogsheetOrderingModal from './PortLogsheetOrderingModal.ts'

const formatRole = (role) => {
    switch (role) {
        case 'admin':
            return 'Admin'
        case 'organization_admin':
            return 'Organization Admin'
        case 'organization_operator':
            return 'Organization Operator'
        case 'organization_superuser':
            return 'Organization Super User'
        case 'organization_manager':
            return 'Organization Manager'
        case 'user':
            return 'User'
        default:
            return 'User'
    }
}

const AdminPortal = (props) => {
    const [users, setUsers] = useState([])
    const [displayCRUDOrganization, setDisplayCRUDOrganization] =
        useState(false)
    const [displayEditUser, setDisplayEditUser] = useState(false)
    const [
        displayPortLogsheetOrderingModal,
        setDisplayPortLogsheetOrderingModal,
    ] = useState(false)
    const [selectedUser, setSelectedUser] = useState({
        username: '',
        email: '',
    })
    const [LoaderWrapper, setisLoading] = useLoader()
    const ShowContentBasedOnRolesWrapper = useShowContentBasedOnRoles()

    useEffect(() => {
        const getAllUsersAsyncWrapper = async () => {
            const users = await getAllUsers()
            setUsers(users.Users)
            setisLoading(false)
        }

        setisLoading(true)
        getAllUsersAsyncWrapper()
    }, [])

    return (
        <Box sx={styles.innerContainer}>
            <ShowContentBasedOnRolesWrapper roles={['admin']}>
                <Box mb={3}>
                    <Button
                        size="large"
                        variant="contained"
                        onClick={() => setDisplayCRUDOrganization('create')}
                    >
                        <Typography>Create Organization</Typography>
                    </Button>
                </Box>
            </ShowContentBasedOnRolesWrapper>

            <ShowContentBasedOnRolesWrapper roles={['admin']}>
                <Box mb={3}>
                    <Button
                        size="large"
                        variant="contained"
                        onClick={() =>
                            setDisplayPortLogsheetOrderingModal(true)
                        }
                    >
                        <Typography>Port logsheet ordering</Typography>
                    </Button>
                </Box>
            </ShowContentBasedOnRolesWrapper>

            <ShowContentBasedOnRolesWrapper roles={['organization_admin']}>
                <Box mb={3}>
                    <Button
                        size="large"
                        variant="contained"
                        onClick={() => setDisplayCRUDOrganization('update')}
                    >
                        <Typography>Edit Organization</Typography>
                    </Button>
                </Box>
            </ShowContentBasedOnRolesWrapper>

            {displayCRUDOrganization !== false ? (
                <CreateOrUpdateOrganization
                    displayModal={displayCRUDOrganization}
                    setDisplayModal={setDisplayCRUDOrganization}
                    operation={displayCRUDOrganization}
                />
            ) : null}

            {displayEditUser ? (
                <EditUser
                    displayModal={displayEditUser}
                    setDisplayModal={setDisplayEditUser}
                    user={selectedUser}
                />
            ) : null}

            {displayPortLogsheetOrderingModal ? (
                <PortLogsheetOrderingModal
                    setDisplayModal={setDisplayPortLogsheetOrderingModal}
                    users={users}
                />
            ) : null}

            <LoaderWrapper>
                <TableContainer component={Paper}>
                    <Table
                        sx={{
                            overflowX: 'auto',
                        }}
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell>Email</TableCell>
                                <TableCell>Verified Email</TableCell>
                                <TableCell>User Role</TableCell>
                                <TableCell>Organization Id</TableCell>
                                <TableCell>User Controls</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users?.map((user) => {
                                const userAttributes = user.Attributes
                                const email = userAttributes.find(
                                    (attribute) => attribute.Name === 'email'
                                ).Value
                                const emailVerified = userAttributes.find(
                                    (attribute) =>
                                        attribute.Name === 'email_verified'
                                ).Value
                                const role =
                                    userAttributes.find(
                                        (attribute) =>
                                            attribute.Name ===
                                            'custom:user_role'
                                    )?.Value ?? 'user'
                                const organizationId =
                                    userAttributes.find(
                                        (attribute) =>
                                            attribute.Name ===
                                            'custom:user_organizationId'
                                    )?.Value ?? '1'

                                return (
                                    <TableRow
                                        key={user.Username}
                                        sx={{
                                            '&:last-child td, &:last-child th':
                                                {
                                                    border: 0,
                                                },
                                        }}
                                    >
                                        <TableCell>{email}</TableCell>
                                        <TableCell>{emailVerified}</TableCell>
                                        <TableCell>
                                            {formatRole(role)}
                                        </TableCell>
                                        <TableCell>{organizationId}</TableCell>
                                        <TableCell>
                                            <Button
                                                size="small"
                                                variant="contained"
                                                onClick={() => {
                                                    setDisplayEditUser(true)
                                                    setSelectedUser({
                                                        username: user.Username,
                                                        email: email,
                                                    })
                                                }}
                                            >
                                                <Typography>
                                                    Edit User
                                                </Typography>
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </LoaderWrapper>
        </Box>
    )
}

export default AdminPortal
